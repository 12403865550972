import { IDestination, IDestinationPostRequest } from "../models/destinations";
import IDestinationListPagination from "../models/destinations/IDestinationListPagination";
import ApiBase from "./ApiBase";

const DESTINATIONS = "/dash/admin/destinations";

export const DestinationsListRequest = async (
  page: number,
  offset: number
): Promise<IDestinationListPagination> => {
  const res = await ApiBase.get(DESTINATIONS, { params: { page, offset } });
  return res.data as IDestinationListPagination;
};

export const DestinationStoreRequest = async (
  destination: IDestinationPostRequest
): Promise<void> => {
  const res = await ApiBase.post(DESTINATIONS, destination);
};

export const DestinationUpdateRequest = async (
  destination: IDestinationPostRequest,
  id: number
): Promise<void> => {
  const res = await ApiBase.put(`${DESTINATIONS}/${id}/update`, destination);
};
