
import { CityState, StateFederation } from "@/services/api/ApiUser";
import { ICity, IStateFederation } from "@/services/models";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { GetCepData } from "@/services/api/ApiCep";
import { notificationSuccess } from "@/utils/NotificationUtils";
import {
  IDestination,
  IDestinationPostRequest
} from "@/services/models/destinations";
import {
  DestinationsListRequest,
  DestinationStoreRequest,
  DestinationUpdateRequest
} from "@/services/api/ApiDestination";
import { RegionalsList } from "@/services/api/ApiRegionals";
import { IRegional } from "@/services/models/regionals";
@Component({
  validations: {
    form: {
      regional_origin_id: { required },
      regional_destiny_id: { required },
      partial_destinations: { required }
    }
  }
})
export default class DestinationsForm extends Vue {
  loading = false;
  @Prop() open_modal!: boolean;
  @Prop() destination!: IDestinationPostRequest & { id: number };
  form: IDestinationPostRequest = {};
  state_list: IStateFederation[] = [];
  cities_list: ICity[] = [];
  destinations: IRegional[] = [];

  @Watch("destination")
  async loadEdit(): Promise<void> {
    this.form = this.destination;
  }

  @Watch("open_modal", { deep: true })
  async loadData(): Promise<void> {
    if (this.open_modal) {
      await Promise.all([this.list()]);
    } else {
      this.form = {};
    }
  }

  async list(): Promise<void> {
    const resp = await RegionalsList(1, 999);
    this.destinations = resp.data;
  }

  async changeState(id: number): Promise<void> {
    const cities = await CityState(id);
    this.cities_list = cities;
  }

  close(): void {
    this.$emit("close_modal");
    this.$v.$reset();
    this.form = {};
  }

  async save(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        if (this.destination.id) {
          const response = await DestinationUpdateRequest(
            this.form,
            this.destination.id
          );
        } else {
          const response = await DestinationStoreRequest(this.form);
        }

        notificationSuccess(
          `${this.$t("users.messages.success.message.create")}`
        );
        this.close();
        this.$emit("reload_page");
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
}
