
import { Component, Vue, Watch } from "vue-property-decorator";
import { IDestination } from "@/services/models/destinations";
import { DestinationsListRequest } from "@/services/api/ApiDestination";
import DestinationForm from "./destination-form/DestinationsForm.vue";
@Component({ components: { DestinationForm } })
export default class Destinations extends Vue {
  loading = false;
  openModal = false;
  items: IDestination[] = [];
  perPage = 10;
  currentPage = 1;
  totalRows = 0;
  pages = 1;
  filter = "";
  fields: { key: string; label: string }[] = [
    { key: "id", label: `${this.$t("destinations.fields.id")}` },
    { key: "regional_origin_name", label: "Origem" },
    { key: "regional_destiny_name", label: "Destino" },
    { key: "actions", label: "Ações" }
  ];
  pageOptions = [10, 25, 50, 100];
  destinationToEdit = {};

  @Watch("perPage")
  async onPerPageChange() {
    await this.list();
  }
  @Watch("currentPage")
  async onCurrentPageChange(): Promise<void> {
    await this.list();
  }

  showModal(): void {
    this.openModal = true;
  }

  closeModal(): void {
    this.openModal = false;
    this.destinationToEdit = {};
  }

  async mounted(): Promise<void> {
    await this.list();
  }

  openEditModal(data: any): void {
    const partial_destinations = data.partial_destinations.map(
      (pd: { id: number }) => pd.id
    );
    this.destinationToEdit = { ...data, partial_destinations };
    console.log(this.destinationToEdit);
    this.showModal();
  }

  async list(): Promise<void> {
    this.loading = true;
    try {
      const response = await DestinationsListRequest(
        this.currentPage,
        this.perPage
      );
      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
}
