var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","title":_vm.destination.id
      ? _vm.$t('destinations.modal.form.title.update')
      : _vm.$t('destinations.modal.form.title.create')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("destinations.modal.form.cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("destinations.modal.form.save"))+" ")])]},proxy:true}]),model:{value:(_vm.open_modal),callback:function ($$v) {_vm.open_modal=$$v},expression:"open_modal"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('destinations.fields.regional_origin'),"label-class":""}},[_c('b-form-select',{class:!_vm.$v.form.regional_origin_id.required &&
                _vm.$v.form.regional_origin_id.$dirty
                  ? 'is-invalid'
                  : '',attrs:{"size":"sm","options":_vm.destinations,"value-field":"id","text-field":"name","state":!_vm.$v.form.regional_origin_id.required &&
                _vm.$v.form.regional_origin_id.$dirty
                  ? false
                  : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":undefined,"disabled":true}},[_vm._v(" "+_vm._s(_vm.destinations.length > 0 ? _vm.$t("destinations.modal.form.option") : _vm.$t("destinations.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.regional_origin_id),callback:function ($$v) {_vm.$set(_vm.form, "regional_origin_id", $$v)},expression:"form.regional_origin_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('destinations.fields.regional_destiny'),"label-class":""}},[_c('b-form-select',{class:!_vm.$v.form.regional_destiny_id.required &&
                _vm.$v.form.regional_destiny_id.$dirty
                  ? 'is-invalid'
                  : '',attrs:{"size":"sm","options":_vm.destinations,"value-field":"id","text-field":"name","state":!_vm.$v.form.regional_destiny_id.required &&
                _vm.$v.form.regional_destiny_id.$dirty
                  ? false
                  : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":undefined,"disabled":true}},[_vm._v(" "+_vm._s(_vm.destinations.length > 0 ? _vm.$t("destinations.modal.form.option") : _vm.$t("destinations.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.regional_destiny_id),callback:function ($$v) {_vm.$set(_vm.form, "regional_destiny_id", $$v)},expression:"form.regional_destiny_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('destinations.fields.partial_destinations'),"label-class":""}},[_c('b-form-select',{class:!_vm.$v.form.partial_destinations.required &&
                _vm.$v.form.partial_destinations.$dirty
                  ? 'is-invalid'
                  : '',attrs:{"size":"sm","options":_vm.destinations,"value-field":"id","text-field":"name","multiple":"","state":!_vm.$v.form.partial_destinations.required &&
                _vm.$v.form.partial_destinations.$dirty
                  ? false
                  : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":undefined,"disabled":true}},[_vm._v(" "+_vm._s(_vm.destinations.length > 0 ? _vm.$t("destinations.modal.form.option") : _vm.$t("destinations.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.partial_destinations),callback:function ($$v) {_vm.$set(_vm.form, "partial_destinations", $$v)},expression:"form.partial_destinations"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }